window.bootstrap = require('bootstrap');
window._ = require('lodash');
window.Popper = require('@popperjs/core').default;

const navbarToggler = document.getElementById('navbar-toggler');
if(navbarToggler) {
  navbarToggler.addEventListener('click', () => {
    navbarToggler.classList.toggle('toggled');
    document.getElementById('navbarToggler').classList.toggle('show');
    document.body.classList.toggle('toggled');
  });
}
